<template>
  <div class="index_con">
    <div v-if="!system.isPhone">
      <!-- 顶部菜单栏 -->
      <top-menu-bar
        :curScroll="curScroll"
        @curIndex="curIndex"
        @goIndex="goIndex"
        @goLogon="goLogon"
      />
    </div>
    <!-- 
      wap端
     -->
    <div v-else>
      <bar-wap
        class="wap_bar_menu"
        :curScroll="curScroll"
        @curIndex="curIndex"
        @goIndex="goIndex"
        @goLogon="goLogon"
      />
    </div>

    <!-- 入驻指南 -->
    <div class="content">
      <img
        src="https://assets-js.myazstore.com/azg/prod/img/applyIntr/bg.png"
        class="bg"
      />
      <div class="text-content">
        <el-row :gutter="20" class="text-layout">
          <el-col :sm="12">
            <div>
              <img
                src="https://assets-js.myazstore.com/azg/prod/img/applyIntr/title.png"
                class="title"
              />
            </div>
            <div class="all-steps">
              <div
                style="width: 50%;"
                v-for="(item, index) in stepList"
                :key="item.textMain"
              >
                <div class="step">
                  <img
                    :src="
                      `https://assets-js.myazstore.com/azg/prod/img/applyIntr/${index +
                        1}.png`
                    "
                    class="step-img"
                  />
                  <div class="step-info">
                    <div class="text-header">
                      <span class="font600w font24 text-main">{{
                        $t(`applyIntr.stepList[${index}].textMain`)
                      }}</span
                      ><span class="text-time font16">{{
                        $t(`applyIntr.stepList[${index}].textTime`)
                      }}</span>
                    </div>
                    <div class="font16 text-detail">
                      {{ $t(`applyIntr.stepList[${index}].textDetail`) }}
                    </div>
                    <a
                      v-if="item.textLink"
                      href="javascript:void(0)"
                      class="font16 text-link"
                      @click="openYuQue(item.linkType)"
                      >{{ $t(`applyIntr.stepList[${index}].textLink`) }} ></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <el-button
              class="join"
              type="primary"
              round
              @click="$router.push('/applyPop')"
            >
              {{ $t("applyIntr.submit") }}
            </el-button>
          </el-col>
          <el-col :sm="12">
            <video
              src="https://assets-video.myazstore.com/azg-website/company_introduction_2023_11_22.mp4"
              class="video"
              loop
              controls="controls"
            ></video>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 底部按钮 -->
    <!-- <el-button type="primary" round @click="$router.push('/applyPop')">
      立即入驻
    </el-button> -->
    <!-- 右侧悬挂 -->
    <div @click="openYuQue('all')" class="right_qes">
      <img src="https://assets-js.myazstore.com/azg/prod/img/applyIntr/help.png" width="100%" />
    </div>
    <!-- 底部 -->
    <div v-if="!system.isPhone">
      <bottom-det class="bottom" />
    </div>
    <div v-else>
      <wap-bottom />
    </div>
  </div>
</template>
<script>
import TopMenuBar from "@/views/publicComponents/TopMenuBar";
import BarWap from "@/views/publicComponents/BarWap";
import BottomDet from "@/views/publicComponents/BottomDet";
import WapBottom from "@/views/publicComponents/WapBottom";
export default {
  name: "CompanyIntr",
  components: {
    TopMenuBar,
    BarWap,
    BottomDet,
    WapBottom,
  },
  data() {
    return {
      curScroll: 0,
      stepList: [
        {
          textLink: true,
          linkType: "data",
        },
        {
          textLink: false,
        },
        {
          textLink: true,
          linkType: "money",
        },
        {
          textLink: false,
        },
      ],
    };
  },
  methods: {
    curIndex(index) {
      this.curScroll = index;
    },
    goIndex() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
    goLogon() {
      this.$router.push("/login");
    },
    openYuQue(val) {
      switch (val) {
        case "data":
          return window.open(
            "https://azglobal.yuque.com/czpgr6/tav07b/dys016fhbwy3siha?singleDoc#",
            "_blank"
          );
        case "money":
          return window.open(
            "https://azglobal.yuque.com/czpgr6/tav07b/bota4wixnn0a3umc?singleDoc#",
            "_blank"
          );
        case "all":
          return window.open(
            "https://azglobal.yuque.com/r/organizations/homepage",
            "_blank"
          );
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  // height: 700px;
  position: relative;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .text-content {
    padding-top: 80px;
    margin: 0 auto;
    width: calc(100vw * ((1920 - 440) / 1920));
    .text-layout {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .all-steps {
      display: flex;
      flex-wrap: wrap;
    }

    .title {
      width: 100%;
      margin-bottom: 90px;
    }
    .step {
      display: flex;
      width: 90%;
      margin-bottom: 70px;
      .step-img {
        width: 51px;
        height: 53px;
      }
      .step-info {
        text-align: left;
        margin-left: 20px;
        .text-header {
          display: flex;
          align-items: center;
          .text-main {
            color: #863910;
          }
          .text-time {
            color: #994e1a;
            background: rgba(153, 78, 26, 0.11);
            margin-left: 10px;
            padding: 0 10px;
          }
        }
        .text-detail {
          color: #a03a00;
          margin-top: 6px;
          margin-bottom: 6px;
        }
        .text-link {
          color: #a03a00;
          text-decoration: underline;
        }
      }
    }
    .join {
      background: linear-gradient(91deg, #ef5c3e 71%, #ffbc4c 100%);
      border: none;
      width: 100%;
      margin-bottom: 60px;
    }
    .video {
      width: 100%;
      max-width: 600px;
      // height: 400px;
    }
  }
}

.right_qes {
  width: 80px;
  height: 80px;
  position: fixed;
  z-index: 1;
  right: 30px;
  bottom: 20%;
  cursor: pointer;
}
.wap_bar_menu {
  height: calc(100vw * 4.8 / 37.5);
}
// 去掉底部的线条和白框
::v-deep .bottom.footer_con {
  margin-top: 0;
}
::v-deep .bottom .footer_container {
  border-top: none;
}

@media only screen and (max-width: 768px) {
  .font24 {
    font-size: 24px;
  }
  .font16 {
    font-size: 16px;
  }
}
</style>
